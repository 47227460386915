<script>
  import { onMount } from 'svelte';
  import Login from './components/Login.svelte';
  import Home from './components/Home.svelte';
  import netlifyIdentity from 'netlify-identity-widget';

  let user = null;

  onMount(() => {
    netlifyIdentity.init();

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      user = JSON.parse(storedUser);
      console.log('Retrieved user from localStorage:', user);
    } else {
      console.log('No user found in localStorage');
    }

    netlifyIdentity.on('login', (userData) => {
      localStorage.setItem('user', JSON.stringify(userData));
      user = userData;
      console.log('User logged in:', user);
    });

    netlifyIdentity.on('logout', () => {
      localStorage.removeItem('user');
      user = null;
      console.log('User logged out');
    });
  });

  function openLogin() {
    netlifyIdentity.open();
  }
</script>

{#if user}
  <Home {user} />
{:else}
  <main>
    <h1>Yo. Hello world!</h1>
    <p>Visit the <a href="https://svelte.dev/tutorial">Svelte tutorial</a> to learn how to build Svelte apps.</p>
    <button on:click={openLogin}>Login</button>
  </main>
{/if}

<style>
  main {
    text-align: center;
    margin-top: 50px;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
</style>