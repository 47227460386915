<!-- src/components/Home.svelte -->
<script>
    import netlifyIdentity from 'netlify-identity-widget';
  
    export let user;
  
    function logout() {
      netlifyIdentity.logout();
    }
  </script>
  
  <main>
    <h1>9 Welcome to The House Bet, {user.user_metadata.full_name}!</h1>
    <p>1 This is your home page after logging in.</p>
    <button on:click={logout}>Logout</button>
  </main>
  
  <style>
    main {
      text-align: center;
      margin-top: 50px;
    }
  
    button {
      margin-top: 20px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
    }
  </style>